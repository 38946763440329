<template>
  <div>
    <div class="mains"></div>
    <van-row
      class="main"
      style="margin-top:0rem;margin-bottom:0.5rem;background-color:#fff"
    >
      <van-col span="5" offset="1">
        <van-image
          :src="require('../../../assets/logo.png')"
          style="width:100%;height:70%;margin-top:0.5rem"
        />
      </van-col>
      <van-col span="18" class="headtext">
        <van-row style="margin-top:0.5rem">
          <van-col span="23" offset="1">
            工作工厂：{{ userData.tenantName }}
          </van-col>
        </van-row>
        <van-row>
          <van-col span="23" offset="1" style="margin-top:0.4rem">
            工号：{{ userData.loginId }} 姓名：{{ userData.nickName }}
          </van-col>
        </van-row>
        <!-- <van-row v-if="type">
          <van-col
            v-if="type"
            span="23"
            offset="1"
            style="margin-top:0.3rem;display: flex;align-items: center;"
          >
            今日工作：{{ workload }}件 ￥{{ wages }}
            <span
              style="margin-left:3vw;display: flex;align-items: center;"
              @click="refreshs"
            >
              <img
                style="width:4vw;height:4vw;"
                :src="require('../../../assets/refresh.png')"
              />
              <span style="font-size: 10px;color:#1890ff">刷新</span>
            </span>
          </van-col>
        </van-row>
        <van-row v-if="!type && productionMain">
          <van-col
            span="24"
            style="margin-top:0.3rem;display: flex;align-items: center;"
          >
            <span
              style="margin-left:3vw;display: flex;align-items: center;"
              @click="seachWord"
            >
              <span style="font-size: 10px;color:#1890ff"
                >点击查看今日工作量</span
              >
            </span>
          </van-col>
        </van-row> -->
        <van-row>
          <van-col
            span="24"
            style="margin-top:0.3rem;display: flex;align-items: center;"
          >
            <span
              style="margin-left:3vw;display: flex;align-items: center;"
              @click="seachWordto"
            >
              <span style="font-size: 10px;color:#1890ff"
                >点击查看今日工作量</span
              >
            </span>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
import { Image as VanImage } from "vant";
import { Row, Col } from "vant";
import { getUserWork, GetWorkloadDisplay } from "@/config/_api";
export default {
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [VanImage.name]: VanImage,
    [Row.name]: Row,
    [Col.name]: Col,
  },
  props: {
    qperationstatus: {
      type: Boolean,
      default: false,
    },
    productionMain: {
      type: Boolean,
      default: false,
    },
    reType: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getItemFun();
    if (this.qperationstatus) {
      this.type = false;
    }
  },
  updated() {
    this.type = this.reType;
  },
  data() {
    return {
      productionMains: false,
      userData: "",
      wages: "",
      workload: "",
      type: this.reType,
    };
  },
  methods: {
    seachWordto(){
      this.$router.push('/dailySalary')
    },
    seachWord() {
      this.type = true;
    },
    async workloadDisplay() {
      let { data } = await GetWorkloadDisplay();
      this.type = data;
    },
    async refreshs() {
      let { data } = await getUserWork();
      let { wages, workload } = data.data;
      this.wages = wages.toFixed(2);
      this.workload = workload;
      localStorage.setItem("wages", wages.toFixed(2));
      localStorage.setItem("workload", workload);
    },
    getItemFun() {
      if (localStorage.getItem("userData")) {
        this.userData = JSON.parse(localStorage.getItem("userData"));
      }
      this.wages = localStorage.getItem("wages");
      this.workload = localStorage.getItem("workload");
      // localStorage.setItem("announcement", 1);
    },

    onClickCart() {
      this.$router.push("cart");
    },
  },
};
</script>
<style scoped>
.headtext {
  font-size: 10px;
  color: grey;
}
.main {
  width: 100%;
  position: fixed;
  top: 0px;
  height: 5rem;
  z-index: 1;
}
</style>
